import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { apiProvider } from '../../shared/api/apiProvider';
import { Helmet } from 'react-helmet';
import { ContentWrapper, IconLink, LoaderSmall, NavSlim } from '../../components';
import React, { useMemo, useRef, useState } from 'react';
import { useContainerHeight } from '../../shared/utils/useContainerHeight';
import { rooDate, rooFmt } from '../../shared/utils';
import { DropdownOption, rooEnum, useAccountManagersOptions, useAppStore } from '../../shared/store';
import {
  CommissionInvoiceEntry,
  CommissionIssueEntry,
  CommissionPaymentStatus,
  GetCommissionReportPayload,
  InvoiceStatus,
  MarkCommissionPaymentPayload
} from '@roo/api';
import { sumBy } from 'lodash';
import { Routes } from '../../shared/routing';
import { Autocomplete, Stack, Switch, TextField, Typography } from '@mui/material';
import { Badge } from 'react-bootstrap';
import { DateRangeChips, makeDefaultChipInterval, SelectedDateRange } from '../../components/DateRangeChips';
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table';

export const CommissionsReportPage = () => {
  const [acctManager, setAcctManager] = useState<DropdownOption<string>>(null);
  const [invoiceStatus, setInvoiceStatus] = useState<DropdownOption<InvoiceStatus>>(null);
  const [paymentStatus, setPaymentStatus] = useState<DropdownOption<CommissionPaymentStatus>>(null);
  const [dateSelection, setDateSelection] = useState<SelectedDateRange>(makeDefaultChipInterval('mtd'));
  const { isLoading, data } = useQuery(
    [
      'commissions',
      'getReport',
      acctManager,
      dateSelection.startDate,
      dateSelection.endDate,
      invoiceStatus?.value,
      paymentStatus?.value
    ],
    () =>
      apiProvider.commissionsClient.getCommissionReport(
        new GetCommissionReportPayload({
          startDate: rooDate.makeInstant(dateSelection.startDate),
          endDate: rooDate.makeInstant(dateSelection.endDate),
          accountManagerId: acctManager?.value,
          invoiceStatus: invoiceStatus?.value,
          paymentStatus: paymentStatus?.value
        })
      )
  );

  const hideReports = useAppStore((x) => x.hideReports);

  return (
    <>
      <Helmet>
        <title>Commissions - Walkthroo</title>
      </Helmet>
      <NavSlim breadcrumbs={[{ text: 'Commissions' }]} />
      <ContentWrapper className="container-property-list no-transparent">
        <Stack spacing={3}>
          <Stack spacing={2} direction={'row'} justifyContent={'center'} alignItems={'center'} flexWrap={'wrap'}>
            <AccountManagerFilter value={acctManager} setValue={setAcctManager} />
            <InvoiceStatusFilter value={invoiceStatus} setValue={setInvoiceStatus} />
            <PaymentStatusFilter value={paymentStatus} setValue={setPaymentStatus} />
            <DateRangeChips value={dateSelection} setValue={setDateSelection} />
          </Stack>
          {isLoading && (
            <div className={'text-center p-4'}>
              <LoaderSmall />
            </div>
          )}
          {!isLoading && !hideReports && <CommissionsTable data={data} />}
        </Stack>
      </ContentWrapper>
    </>
  );
};

const AccountManagerFilter = ({
  value,
  setValue
}: {
  value: DropdownOption<string>;
  setValue: (val: DropdownOption<string>) => void;
}) => {
  const accountManagerOpts = useAccountManagersOptions();

  return (
    <Autocomplete
      multiple={false}
      value={value}
      onChange={(_, val) => setValue(val as DropdownOption<string>)}
      sx={{ minWidth: '300px', marginLeft: '10px' }}
      renderInput={(params) => (
        <TextField variant={'standard'} {...params} size={'small'} label="Choose acct manager" />
      )}
      options={accountManagerOpts ?? []}
    />
  );
};

export const paymentStatusFilter: DropdownOption<CommissionPaymentStatus>[] = [
  { value: CommissionPaymentStatus.Paid, label: 'Paid' },
  { value: CommissionPaymentStatus.NotPaid, label: 'Not Paid' }
];

const PaymentStatusFilter = ({
  value,
  setValue
}: {
  value: DropdownOption<CommissionPaymentStatus>;
  setValue: (val: DropdownOption<CommissionPaymentStatus>) => void;
}) => {
  return (
    <Autocomplete
      multiple={false}
      value={value}
      onChange={(_, val) => setValue(val as DropdownOption<CommissionPaymentStatus>)}
      sx={{ minWidth: '300px', marginLeft: '10px' }}
      renderInput={(params) => (
        <TextField variant={'standard'} {...params} size={'small'} label="Choose payment status" />
      )}
      options={paymentStatusFilter}
    />
  );
};

export const invoiceStatusFilters: DropdownOption<InvoiceStatus>[] = [
  { value: InvoiceStatus.Paid, label: 'Paid' },
  { value: InvoiceStatus.PaymentSent, label: 'Payment Sent' },
  { value: InvoiceStatus.Accepted, label: 'Accepted' },
  { value: InvoiceStatus.PendingReview, label: 'Pending Review' }
];

const InvoiceStatusFilter = ({
  value,
  setValue
}: {
  value: DropdownOption<InvoiceStatus>;
  setValue: (val: DropdownOption<InvoiceStatus>) => void;
}) => {
  return (
    <Autocomplete
      multiple={false}
      value={value}
      onChange={(_, val) => setValue(val as DropdownOption<InvoiceStatus>)}
      sx={{ minWidth: '300px', marginLeft: '10px' }}
      renderInput={(params) => (
        <TextField variant={'standard'} {...params} size={'small'} label="Choose invoice status" />
      )}
      options={invoiceStatusFilters}
    />
  );
};

const CommissionsTable = ({ data }: { data: CommissionIssueEntry[] }) => {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const height = useContainerHeight({ itemRef: tableContainerRef, defaultHeight: 500 });

  const columns = useMemo<MRT_ColumnDef<CommissionIssueEntry>[]>(
    () => [
      {
        accessorKey: 'property.address.address1',
        header: 'Address',
        Cell: ({ row }) => row.original.property?.displayAddress
      },
      {
        accessorKey: 'referenceDate.dateTime',
        header: 'Date',
        Cell: ({ row }) => rooFmt.instantDate(row.original.referenceDate)
      },
      {
        accessorKey: 'friendlyId',
        header: 'Issue #',
        Cell: ({ row }) => (
          <IconLink
            target="_blank"
            style={{ whiteSpace: 'nowrap' }}
            to={{
              pathname: Routes.IssueView,
              params: { issueId: row.original.issueId, workorderId: row.original.workorderId }
            }}
            text={row.original.friendlyId}
          />
        )
      },
      {
        accessorKey: 'invoiceStatus',
        header: 'Status',
        Cell: ({ row }) =>
          row.original.invoiceStatus && (
            <Badge bg={rooEnum.invoiceStatus.color(row.original.invoiceStatus) as any}>
              {rooEnum.invoiceStatus.display(row.original.invoiceStatus)}
            </Badge>
          )
      },
      {
        accessorKey: 'accountManager.fullName',
        header: 'Account Manager'
      },
      {
        accessorKey: 'rooAmount',
        header: 'Roo Amt.',
        Cell: ({ row }) => rooFmt.money(row.original.rooAmount),
        footer: rooFmt.money(sumBy(data, (x) => x.rooAmount ?? 0)),
        size: 150
      },
      {
        accessorKey: 'vendorAmount',
        header: 'Vendor Amt.',
        Cell: ({ row }) => rooFmt.money(row.original.vendorAmount),
        footer: rooFmt.money(sumBy(data, (x) => x.vendorAmount ?? 0)),
        size: 150
      },
      {
        accessorKey: 'profit',
        header: 'Profit',
        Cell: ({ row }) => rooFmt.money(row.original.profit),
        footer: rooFmt.money(sumBy(data, (x) => x.profit ?? 0)),
        size: 150
      },
      {
        accessorKey: 'operatorShare',
        header: 'Operator $',
        Cell: ({ row }) => rooFmt.money(row.original.operatorShare),
        footer: rooFmt.money(sumBy(data, (x) => x.operatorShare ?? 0)),
        size: 150
      },
      {
        accessorKey: 'supportShare',
        header: 'Support $',
        Cell: ({ row }) => rooFmt.money(row.original.supportShare),
        footer: rooFmt.money(sumBy(data, (x) => x.supportShare ?? 0)),
        size: 150
      },
      {
        accessorKey: 'rooShare',
        header: 'Roo $',
        Cell: ({ row }) => rooFmt.money(row.original.rooShare),
        footer: rooFmt.money(sumBy(data, (x) => x.rooShare ?? 0)),
        size: 150
      },
      {
        accessorKey: 'paymentStatus',
        header: 'Paid',
        Cell: ({ row }) => <PaymentSwitch entry={row.original} />
      }
    ],
    [data]
  );

  return (
    <MaterialReactTable
      enableHiding={false}
      enableColumnDragging={false}
      enableFullScreenToggle={false}
      enableDensityToggle={false}
      enableTopToolbar={false}
      enableBottomToolbar={false}
      enableColumnActions={false}
      enablePagination={false}
      enableStickyFooter
      enableStickyHeader
      columns={columns}
      data={data}
      getRowId={(row) => row.issueId}
      initialState={{
        sorting: [
          {
            id: 'referenceDate.dateTime',
            desc: true
          }
        ],
        density: 'compact'
      }}
      muiTableContainerProps={{
        ref: tableContainerRef,
        sx: { maxHeight: `${height}px` }
      }}
      renderDetailPanel={({ row }) => (
        <Stack spacing={2} px={4} py={2}>
          <Stack spacing={1}>
            <Typography variant={'h6'}>Outgoing</Typography>
            {row.original.outgoingInvoices.length === 0 && <Typography>No outgoing invoices</Typography>}
            {row.original.outgoingInvoices.length > 0 && <InvoiceTable data={row.original.outgoingInvoices} />}
          </Stack>

          <Stack spacing={1}>
            <Typography variant={'h6'}>Incoming</Typography>
            {row.original.incomingInvoices.length === 0 && <Typography>No incoming invoices</Typography>}
            {row.original.incomingInvoices.length > 0 && <InvoiceTable data={row.original.incomingInvoices} />}
          </Stack>
        </Stack>
      )}
    />
  );
};

const PaymentSwitch = ({ entry }: { entry: CommissionIssueEntry }) => {
  const queryClient = useQueryClient();

  const [clientPaid, setClientPaid] = useState(entry.paymentStatus === CommissionPaymentStatus.Paid);
  const [serverPaid, setServerPaid] = useState(entry.paymentStatus === CommissionPaymentStatus.Paid);
  const [error, setError] = useState<string | null>(null);

  const mutation = useMutation({
    mutationFn: () =>
      apiProvider.commissionsClient.markCommissionPayment(
        new MarkCommissionPaymentPayload({
          issueId: entry.issueId,
          paid: !serverPaid
        })
      ),
    onSuccess: () => {
      const newPaid = !serverPaid;
      setServerPaid(newPaid);
      queryClient
        .getQueryCache()
        .findAll()
        .filter(
          (query) =>
            Array.isArray(query.queryKey) && query.queryKey[0] === 'commissions' && query.queryKey[1] === 'getReport'
        )
        .forEach((query) => {
          queryClient.setQueryData<CommissionIssueEntry[] | undefined>(query.queryKey, (oldData: any) => {
            if (!oldData) {
              return oldData;
            }
            return oldData.map((item: any) =>
              item.issueId === entry.issueId
                ? {
                    ...item,
                    paymentStatus: newPaid ? CommissionPaymentStatus.Paid : CommissionPaymentStatus.NotPaid
                  }
                : item
            );
          });
        });
      setError(null);
    },
    onError: () => {
      setClientPaid(serverPaid);
      setError('Error');
    }
  });

  // Handle switch toggle
  const handleToggle = () => {
    // Optimistically update the switch
    setClientPaid((prev) => !prev);
    // Trigger the mutation
    mutation.mutate();
  };

  return (
    <>
      <Switch checked={clientPaid} disabled={mutation.isLoading} onChange={handleToggle} />
      {error && <Badge bg="danger">{error}</Badge>}
    </>
  );
};

const InvoiceTable = ({ data }: { data: CommissionInvoiceEntry[] }) => {
  const columns = useMemo<MRT_ColumnDef<CommissionInvoiceEntry>[]>(
    () => [
      {
        accessorKey: 'friendlyIssueId',
        header: 'Issue #',
        Cell: ({ row }) => (
          <IconLink
            target="_blank"
            style={{ whiteSpace: 'nowrap' }}
            to={{
              pathname: Routes.IssueView,
              params: { issueId: row.original.issueId, workorderId: row.original.workorderId }
            }}
            text={row.original.friendlyIssueId}
          />
        )
      },
      {
        accessorKey: 'friendlyId',
        header: 'Invoice #'
      },
      {
        accessorKey: 'date',
        header: 'Date',
        Cell: ({ row }) => rooFmt.instantDate(row.original.date)
      },
      {
        accessorKey: 'status',
        header: 'Status',
        Cell: ({ row }) => (
          <Badge bg={rooEnum.invoiceStatus.color(row.original.status) as any}>
            {rooEnum.invoiceStatus.display(row.original.status)}
          </Badge>
        )
      },
      {
        accessorKey: 'finalTotal',
        header: 'Final Total',
        Cell: ({ row }) => rooFmt.money(row.original.finalTotal)
      }
    ],
    []
  );

  return (
    <MaterialReactTable
      enableHiding={false}
      enableColumnDragging={false}
      enableFullScreenToggle={false}
      enableDensityToggle={false}
      enableTopToolbar={false}
      enableBottomToolbar={false}
      enableColumnActions={false}
      enablePagination={false}
      columns={columns}
      data={data}
    />
  );
};
